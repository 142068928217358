import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import labelsData from "../data/labels";

const Label = () => {
  const { label } = useParams();

  const [isEditMode, setIsEditMode] = useState(false);
  const [eLabel, setELabel] = useState(null);
  const [error, setError] = useState(null);
  const [expandedSections, setExpandedSections] = useState({});

  useEffect(() => {
    if (labelsData[label]) {
      setELabel({
        ...labelsData[label],
        recycleItems: [
          { material: "Tappo", description: "FOR 51 tappo-sughero" },
          { material: "Capsula", description: "PVC 3 capsula-plastica" },
          { material: "Bottiglia", description: "GL 72 bottiglia-vetro" },
          
        ],
      });
      setError(null);
    } else {
      setELabel(null);
      setError("Etichetta non trovata.");
    }
  }, [label]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setELabel({ ...eLabel, [name]: value });
  };

  const handleSave = () => {
    setIsEditMode(false);
    console.log("Dati salvati:", eLabel);
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleRecycleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRecycleItems = [...eLabel.recycleItems];
    const itemKey = name.split("-")[0];
    updatedRecycleItems[index][itemKey] = value;
    setELabel({ ...eLabel, recycleItems: updatedRecycleItems });
  };

  if (error) {
    return (
      <div style={styles.container}>
        <h1>{error}</h1>
      </div>
    );
  }

  if (!eLabel) {
    return (
      <div style={styles.container}>
        <h1>Caricamento...</h1>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      {/* Logo Aziendale */}
      <div style={styles.logoContainer}>
        <img
          src={require("../data/media/logo.png")} // Cambia con il tuo logo
          alt="Logo Aziendale"
          style={styles.logo}
        />
      </div>

      {/* Immagine Etichetta */}
      <div style={styles.imageContainer}>
        <img src={eLabel.labelImage} alt="Etichetta Vino" style={styles.image} />
      </div>

      {/* Contenuto principale */}
      {isEditMode ? (
        <div>
          {renderAccordionContent("Informazioni sul vino", "wine", true)}
          {renderAccordionContent("Ingredienti e allergeni", "allergens", true)}
          {renderAccordionContent("Valori nutrizionali", "nutrition", true)}
          {renderAccordionContent("Riciclo", "recycle", true)}

          <div style={styles.editButtonContainer}>
            <button onClick={handleSave} style={styles.saveButton}>
              Salva
            </button>
          </div>
        </div>
      ) : (
        <div>
          {renderAccordionContent("Informazioni sul vino", "wine")}
          {renderAccordionContent("Ingredienti e allergeni", "allergens")}
          {renderAccordionContent("Valori nutrizionali", "nutrition")}
          {renderAccordionContent("Riciclo", "recycle")}
        </div>
      )}
    </div>
  );

  function renderAccordionContent(header, section, isEditable = false) {
    const isOpen = expandedSections[section];
    return (
      <div style={styles.accordion}>
        <div
          style={styles.accordionHeader}
          onClick={() => toggleSection(section)}
        >
          <h2 style={styles.accordionTitle}>{header}</h2>
          <span style={styles.chevron}>{isOpen ? "▼" : "►"}</span>
        </div>
        {isOpen && (
          <div style={styles.accordionContent}>
            {isEditable
              ? renderEditableContent(section)
              : renderViewContent(section)}
          </div>
        )}
      </div>
    );
  }

  function renderEditableContent(section) {
    switch (section) {
      case "wine":
        return (
          <>
            <input
              type="text"
              name="wineName"
              value={eLabel.wineName}
              onChange={handleChange}
              style={styles.input}
            />
            <input
              type="text"
              name="vintage"
              value={eLabel.vintage}
              onChange={handleChange}
              style={styles.input}
            />
            <input
              type="text"
              name="grapeVarieties"
              value={eLabel.grapeVarieties}
              onChange={handleChange}
              style={styles.input}
            />
            <input
              type="text"
              name="wineType"
              value={eLabel.wineType}
              onChange={handleChange}
              style={styles.input}
            />
          </>
        );
      case "allergens":
        return (
          <>
            <textarea
              name="ingredients"
              value={eLabel.ingredients}
              onChange={handleChange}
              style={styles.textarea}
              placeholder="Ingredienti"
            />
            <textarea
              name="allergens"
              value={eLabel.allergens}
              onChange={(e) =>
                handleChange({
                  target: { name: "allergens", value: e.target.value.split(", ") },
                })
              }
              style={styles.textarea}
              placeholder="Allergeni"
            />
          </>
        );
      case "nutrition":
        return (
          <>
            <textarea
              name="nutritionalInformation"
              value={eLabel.nutritionalInformation}
              onChange={handleChange}
              style={styles.textarea}
              placeholder="Valori nutrizionali"
            />
          </>
        );
      case "recycle":
        return (
          <>
            {eLabel.recycleItems.map((item, index) => (
              <div key={index}>
                <input
                  type="text"
                  name={`recycleMaterial-${index}`}
                  value={item.material}
                  onChange={(e) => handleRecycleChange(e, index)}
                  style={styles.input}
                />
                <input
                  type="text"
                  name={`recycleDescription-${index}`}
                  value={item.description}
                  onChange={(e) => handleRecycleChange(e, index)}
                  style={styles.input}
                />
              </div>
            ))}
          </>
        );
      default:
        return null;
    }
  }

  function renderViewContent(section) {
    switch (section) {
      case "wine":
        return (
          <>
            <p>
              <strong>Nome:</strong> {eLabel.wineName}
            </p>
            <p>
              <strong>Annata:</strong> {eLabel.vintage}
            </p>
            <p>
              <strong>Varietà d'uva:</strong> {eLabel.grapeVarieties}
            </p>
            <p>
              <strong>Tipo:</strong> {eLabel.wineType}
            </p>
            <p>
              <strong>Numero di lotto:</strong> {eLabel.lotNumber}
            </p>
          </>
        );
      case "allergens":
        return (
          <>
            <p>
              <strong>Ingredienti:{" "}</strong>
              {eLabel.ingredients.length > 0 ? eLabel.ingredients.join(", ") : "Nessuno dichiarato"}
            </p>
            <p>
              <strong>Contiene piccole quantità di: </strong>{" "}
              {eLabel.allergens.length > 0 ? eLabel.allergens.join(", ") : "Nessuno dichiarato"}
            </p>
          </>
        );
      case "nutrition":
        return (
          <table style={styles.table}>
            <thead>
              <tr>
                <th colSpan="2" style={styles.tableHeader}>
                  VALORI NURTRIZIONALI MEDI RIFERITI A 100ml DI PRODOTTO
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={styles.tableCell}>Energia</td>
                <td style={styles.tableCell}>{eLabel.nutrition.energy_jl}{' '}kJ{' / '}{eLabel.nutrition.energy_kcal}{' '}kcal</td>
              </tr>
              <tr>
                <td style={styles.tableCell}>Grassi</td>
                <td style={styles.tableCell}>{eLabel.nutrition.fat}{' '}g</td>
              </tr>
              <tr>
                <td style={styles.tableCell}>di cui grassi saturi</td>
                <td style={styles.tableCell}>{eLabel.nutrition.saturatedFat}{' '}g</td>
              </tr>
              <tr>
                <td style={styles.tableCell}>Carboidrati</td>
                <td style={styles.tableCell}>{eLabel.nutrition.carbohydrates}{' '}g</td>
              </tr>
              <tr>
                <td style={styles.tableCell}>di cui zuccheri</td>
                <td style={styles.tableCell}>{eLabel.nutrition.sugars}{' '}g</td>
              </tr>
              <tr>
                <td style={styles.tableCell}>Proteine</td>
                <td style={styles.tableCell}>{eLabel.nutrition.protein}{' '}g</td>
              </tr>
              <tr>
                <td style={styles.tableCell}>Sale</td>
                <td style={styles.tableCell}>{eLabel.nutrition.salt}{' '}g</td>
              </tr>
            </tbody>
          </table>
        );
      case "recycle":
        return (
          <div style={styles.recycleContainer}>
            {eLabel.recycleItems.map((item, index) => (
              <div key={index} style={styles.recycleItem}>
                <p style={styles.recycleText}>
                  <strong>{item.material}:</strong>
                </p>
                <p style={styles.recycleText}>{item.description}</p>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  }
};

const styles = {
  container: { maxWidth: "900px", margin: "0 auto", padding: "20px", backgroundColor: "#f4f4f4", boxShadow: "0 4px 12px rgba(0,0,0,0.1)", borderRadius: "10px" },
  logoContainer: { textAlign: "center", marginBottom: "20px" },
  logo: { maxWidth: "200px", height: "auto" },
  imageContainer: { textAlign: "center", marginBottom: "20px" },
  image: { width: "auto", height: "500px", objectFit: "cover", borderRadius: "15px", boxShadow: "0 2px 8px rgba(0,0,0,0.2)" },
  editButtonContainer: { textAlign: "center", marginBottom: "20px" },
  saveButton: { padding: "10px 20px", backgroundColor: "#28a745", color: "#fff", border: "none", borderRadius: "8px", cursor: "pointer" },
  accordion: { marginBottom: "20px" },
  accordionHeader: { display: "flex", justifyContent: "space-between", cursor: "pointer", backgroundColor: "#333", color: "#fff", padding: "15px", borderRadius: "10px" },
  accordionTitle: { margin: 0, fontWeight: "bold" },
  chevron: { fontSize: "18px" },
  accordionContent: { padding: "15px 20px", backgroundColor: "#f7f7f7", borderRadius: "10px", marginTop: "5px" },
  input: { width: "100%", padding: "10px", margin: "10px 0", borderRadius: "8px", border: "1px solid #ccc" },
  textarea: { width: "100%", height: "100px", padding: "10px", margin: "10px 0", borderRadius: "8px", border: "1px solid #ccc" },
  table: { width: "100%", borderCollapse: "collapse", margin: "10px 0" },
  tableHeader: { backgroundColor: "#444", color: "#fff", textAlign: "center", padding: "10px", fontSize: "16px" },
  tableCell: { border: "1px solid #ccc", padding: "10px" },
  recycleContainer: { marginTop: "15px" },
  recycleItem: { marginBottom: "10px" },
  recycleText: { margin: "5px 0" },
};

export default Label;
