const labelsData = {
    "8b43943080894aa13187dd43c2b83ef9": {
      
      "countryOfOrigin": "Italia",
      "wineName": "Don Ciriaco",
      "vintage": "2019",
      "grapeVarieties": "Taurasi DOCG",
      "wineType": "Rosso",
      "alcoholContent": 14.5,
      "volume": 750,
      "lotNumber": "L202309",
      "ingredients": ["Vino"],
      "allergens": ["Solfiti"],
      "nutrition": {
        "energy": 70,
        "fat": 0,
        "saturatedFat": 0,
        "carbohydrates": 1,
        "sugars": 0.5,
        "protein": 0,
        "salt": 0,
        "acidity": 5.6,
        "sulfur": 120
      },
     
      "additionalNotes": "Servire a 18°C",
      "labelImage": require("./media/donciriaco.jpg")
    },
    "b3d39e6d557a55e052d234ba0781f86a": {
     
      "countryOfOrigin": "Italia",
      "wineName": "Vigna San Michele",
      "vintage": "2018",
      "grapeVarieties": "Taurasi DOCG",
      "wineType": "Rosso",
      "alcoholContent": 13.5,
      "volume": 750,
      "lotNumber": "L202310",
      "allergens": ["Solfiti"],
      "ingredients": ["Vino"],
      "nutrition": {
        "energy": 70,
        "fat": 0,
        "saturatedFat": 0,
        "carbohydrates": 1,
        "sugars": 0.5,
        "protein": 0,
        "salt": 0,
        "acidity": 5.6,
        "sulfur": 120
      },
    
      "additionalNotes": "Servire a 20°C",
      "labelImage": require("./media/vignasanmichele.jpg")
    },
    "cb97a2def3b7f38dd2dd7500ffa08f36": {
    
      "countryOfOrigin": "Italia",
      "wineName": "Buccenere",
      "vintage": "2020",
      "grapeVarieties": "Irpinia Aglianico DOC",
      "wineType": "Rosso",
      "alcoholContent": 13.0,
      "volume": 750,
      "lotNumber": "L202311",
      "allergens": ["Solfiti"],
      "ingredients": ["Vino"],
      "nutrition": {
        "energy": 70,
        "fat": 0,
        "saturatedFat": 0,
        "carbohydrates": 1,
        "sugars": 0.5,
        "protein": 0,
        "salt": 0,
        "acidity": 5.6,
        "sulfur": 120
      },
    
      "additionalNotes": "Servire a 18°C",
      "labelImage": require("./media/buccenere.jpg")
    },
    "9d5ba4fab718852050c01a59fd3ebe27": {
      
      "countryOfOrigin": "Italia",
      "wineName": "Nero Latino",
      "vintage": "2021",
      "grapeVarieties": "Campi Taurasini DOC",
      "wineType": "Rosso",
      "alcoholContent": 14,
      "volume": 750,
      "lotNumber": "L202312",
      "allergens": ["Solfiti"],
      "ingredients": ["Vino"],
      "nutrition": {
        "energy": 70,
        "fat": 0,
        "saturatedFat": 0,
        "carbohydrates": 1,
        "sugars": 0.5,
        "protein": 0,
        "salt": 0,
        "acidity": 5.6,
        "sulfur": 120
      },
      "additionalNotes": "Servire a 16°C",
      "labelImage": require("./media/nerolatino.jpg")
    },
    "b2aa24c2a2091e4386bc89febaaa545f": {
     
      "countryOfOrigin": "Italia",
      "wineName": "XIV",
      "vintage": "2022",
      "grapeVarieties": "Campania Aglianico IGT",
      "wineType": "Rosso",
      "alcoholContent": 14.0,
      "volume": 750,
      "lotNumber": "L202313",
      "allergens": ["Solfiti"],
      "ingredients": ["Vino"],
      "nutrition": {
        "energy": 70,
        "fat": 0,
        "saturatedFat": 0,
        "carbohydrates": 1,
        "sugars": 0.5,
        "protein": 0,
        "salt": 0,
        "acidity": 5.6,
        "sulfur": 120
      },
      "additionalNotes": "Servire a 18°C",
      "labelImage": require("./media/xiv.jpg")
    },
    "3fa0aa6b64f88ea7e0c4e14c377d8275": {
   
      "countryOfOrigin": "Italia",
      "wineName": "Castagnaro",
      "vintage": "2022",
      "grapeVarieties":  "Campania Aglianico IGT",
      "wineType": "Rosso",
      "alcoholContent": 13.5,
      "volume": 750,
      "lotNumber": "L202314",
      "allergens": ["Solfiti"],
      "ingredients": ["Vino"],
      "nutrition": {
        "energy": 70,
        "fat": 0,
        "saturatedFat": 0,
        "carbohydrates": 1,
        "sugars": 0.5,
        "protein": 0,
        "salt": 0,
        "acidity": 5.6,
        "sulfur": 120
      },
     
      "additionalNotes": "Servire a 16°C",
      "labelImage": require("./media/castagnaro.jpg")
    },
    "bf0f665bebb18f9c77d69de156f26d0c": {
    
      "countryOfOrigin": "Italia",
      "wineName": "Macchiarosa",
      "vintage": "2022",
      "grapeVarieties": "Campania Rosato IGT 2022",
      "wineType": "Rosato",
      "alcoholContent": 13,
      "volume": 750,
      "lotNumber": "L202315",
      "allergens": ["Solfiti"],
      "ingredients": ["Vino"],
      "nutrition": {
        "energy": 70,
        "fat": 0,
        "saturatedFat": 0,
        "carbohydrates": 1,
        "sugars": 0.5,
        "protein": 0,
        "salt": 0,
        "acidity": 5.6,
        "sulfur": 120
      },
     
      "additionalNotes": "Servire a 18°C",
      "labelImage": require("./media/macchiarosa.jpg")
    },
    "5922dc09207142bd24aa2f1befdbe284": {
     
      "countryOfOrigin": "Italia",
      "wineName": "Remier 1209",
      "vintage": "2022",
      "grapeVarieties": "Falanghina extra dry",
      "wineType": "Bianco",
      "alcoholContent": 13.50,
      "volume": 750,
      "lotNumber": "L202316",
      "allergens": ["Solfiti"],
      "ingredients": ["Vino"],
      "nutrition": {
        "energy": 70,
        "fat": 0,
        "saturatedFat": 0,
        "carbohydrates": 1,
        "sugars": 0.5,
        "protein": 0,
        "salt": 0,
        "acidity": 5.6,
        "sulfur": 120
      },
     
      "additionalNotes": "Servire a 20°C",
      "labelImage": require("./media/remier.jpg")
    },
    "23d026ab5e7b67384d37ebab4e79df8a": {
    
      "countryOfOrigin": "Italia",
      "wineName": "Bianco Fiore",
      "vintage": "2022",
      "grapeVarieties": "Fiano di Avellino DOCG",
      "wineType": "Bianco",
      "alcoholContent": 14.0,
      "volume": 750,
      "lotNumber": "L202317",
      "allergens": ["Solfiti"],
      "ingredients": ["Vino"],
      "nutrition": {
        "energy": 70,
        "fat": 0,
        "saturatedFat": 0,
        "carbohydrates": 1,
        "sugars": 0.5,
        "protein": 0,
        "salt": 0,
        "acidity": 5.6,
        "sulfur": 120
      },
     
      "additionalNotes": "Servire a 12°C",
      "labelImage": require("./media/biancofiore.jpg")
    },
    "5cbad0ad45ff2ffd561f2f39e84527b2": {
    
      "countryOfOrigin": "Italia",
      "wineName": "Ergo Sum",
      "vintage": "2022",
      "grapeVarieties": "Greco di Tufo DOCG",
      "wineType": "Rosso",
      "alcoholContent": 14.0,
      "volume": 750,
      "lotNumber": "L202318",
      "allergens": ["Solfiti"],
      "ingredients": ["Vino"],
      "nutrition": {
        "energy": 70,
        "fat": 0,
        "saturatedFat": 0,
        "carbohydrates": 1,
        "sugars": 0.5,
        "protein": 0,
        "salt": 0,
        "acidity": 5.6,
        "sulfur": 120
      },
      "additionalNotes": "Servire a 10°C",
      "labelImage": require("./media/ergosum.jpg")
    },
    "a18d437766038ae81820d18bb4797575": {
    
      "countryOfOrigin": "Italia",
      "wineName": "Chiaro di Luna",
      "vintage": "2022",
      "grapeVarieties": "Beneventano IGT",
      "wineType": "Bianco",
      "alcoholContent": 13,
      "volume": 750,
      "lotNumber": "L202319",
      "allergens": [""],
      "ingredients": ["Uva, Conservanti e antiossidanti: Metabisolfito di potassio (E 224)", "Gas e gas di imballaggio: E 941", "Conservanti e antiossidanti: E 300","Agenti stabilizzanti: E 414 e/o E 466"],
      "nutrition": {
        "energy": 70,
        "fat": 0,
        "saturatedFat": 0,
        "carbohydrates": 1,
        "sugars": 0.5,
        "protein": 0,
        "salt": 0,
        "acidity": 5.6,
        "sulfur": 120
      },
     
      "additionalNotes": "Servire a 10°C",
      "labelImage": require("./media/chiarodiluna.jpg")
    },

  "9c1d9db2a60bd821b95da981d9f9c8a86b": {
    
      "countryOfOrigin": "Italia",
      "wineName": "Ladra",
      "vintage": "2022",
      "grapeVarieties": "Coda di Volpe",
      "wineType": "Rosso",
      "alcoholContent": 14.0,
      "volume": 750,
      "lotNumber": "L202318",
      "allergens": ["Solfiti"],
      "ingredients": ["Vino"],
      "nutrition": {
        "energy": 70,
        "fat": 0,
        "saturatedFat": 0,
        "carbohydrates": 1,
        "sugars": 0.5,
        "protein": 0,
        "salt": 0,
        "acidity": 5.6,
        "sulfur": 120
      },
      "additionalNotes": "Servire a 10°C",
      "labelImage": require("./media/ladra.jpg")
    },

    "2e49dea55adb04d7997faeb1ba836cc6": {
    
      "countryOfOrigin": "Italia",
      "wineName": "Rosato D.T.",
      "vintage": "2024",
      "grapeVarieties": "Aglianico",
      "wineType": "Rosato",
      "alcoholContent": 13.5,
      "volume": 750,
      "lotNumber": "L240225",
      "allergens": [""],
      "ingredients": ["Uva, Conservanti e antiossidanti: Metabisolfito di potassio (E 224)", "Gas e gas di imballaggio: E 941", "Conservanti e antiossidanti: E 300","Agenti stabilizzanti: E 414 e/o E 466"],
      "nutrition": {
        "energy_jl": 305,
        "energy_kcal": 74,
        "fat": 0,
        "saturatedFat": 0,
        "carbohydrates": 0,
        "sugars": 0,
        "protein": 0,
        "salt": 0
      },
      "additionalNotes": "Servire a 10°C",
      "labelImage": require("./media/macchiarosa.jpg")
    }


  };
  
  export default labelsData;
  